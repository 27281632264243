window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
    
    require('summernote');
    require('summernote/dist/summernote.js');
    require('select2');
    
//    window.Popper = require('popper.js');
//    window.Tooltip = require('tooltip.js');
  
    window.Cropper = require('cropperjs');
    
    window.datetimepicker = require('pc-bootstrap4-datetimepicker');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js"

import { Calendar } from '@fullcalendar/core';
window.Calendar = Calendar;
import bootstrapPlugin from '@fullcalendar/bootstrap';
window.bootstrapPlugin = bootstrapPlugin;
import dayGridPlugin from '@fullcalendar/daygrid';
window.dayGridPlugin = dayGridPlugin;
import timeGridPlugin from '@fullcalendar/timegrid';
window.timeGridPlugin = timeGridPlugin;
import interactionPlugin from '@fullcalendar/interaction';
window.interactionPlugin = interactionPlugin;
import listPlugin from '@fullcalendar/list';
window.listPlugin = listPlugin;
import deLocale from '@fullcalendar/core/locales/de';
window.deLocale = deLocale;

//import { createPopper } from '@popperjs/core';
//window.createPopper = createPopper;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

/*
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true
    //wsHost: window.location.hostname,
    
    
    
    encrypted: false,
    wsHost: '10.196.20.28',
    wsPort: 6001,
    wssPort: 6001,
    wsPath:'/websocket',
    forceTLS: false,
    enabledTransports: ['ws','wss'],
    
    
    
});
*/